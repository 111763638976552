import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select'
import { EditGraph } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png'
import colourStyles from '../util/reactSelectStyle';

function GraphSettingForm({ setCloseModal, user_det, graph_det, all_graphs, fetchGraphs }) {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      sensor_id: graph_det.sensor_id,
      min_threshold: graph_det.min_threshold === 0 ? graph_det.sensor_id.type?.min_value : graph_det.min_threshold,
      mid_threshold: graph_det.mid_threshold,
      max_threshold: graph_det.max_threshold === 0 ? graph_det.sensor_id.type?.max_value : graph_det.max_threshold,
      fetch_data_period: graph_det.fetch_data_period,
      merged_graphs: graph_det.merged_graphs,
      min_val_color: graph_det.colors[0],
      mid_val_color: graph_det.colors[1],
      max_val_color: graph_det.colors[2],
      name: graph_det.name
    }
  });

  const [sensorOptions, setSensorOptions] = useState([])
  const [unmergedGraphs, setUnmergedGraphs] = useState([])
  const [mergedGraphs, setMergedGraphs] = useState([])

  useEffect(() => {
    console.log("graph setting render");
    const sensors = graph_det.device_id.sensor_id.filter(sensor => sensor._id !== graph_det.sensor_id._id)
    setSensorOptions(sensors)

    // setting merged graph salect options
    let merged_graphs = graph_det?.merged_graphs?.map(graph => { return { value: graph._id, label: graph.name } })
    let unmerged_graphs = all_graphs.filter(graph => graph._id !== graph_det._id)
    unmerged_graphs = unmerged_graphs.map(graph => ({ value: graph._id, label: graph.name }))
    setUnmergedGraphs(unmerged_graphs)
    setMergedGraphs(merged_graphs)

  }, [user_det])

  const handleMergeGraphSelect = (val) => {
    // if (action.action === 'select-option') {
    //   if (mergedGraphs.length >= 3) return alert('Sorry, you can only merge upto 3 graphs currently!')
    //   setMergedGraphs(val)
    // }
    // else if (action.action === 'remove-value') {
    //   console.log(mergedGraphs.filter(graph => !val.includes(graph)));
    //   setMergedGraphs(mergedGraphs.filter(graph => val.includes(graph)))
    //   setUnmergedGraphs(mergedGraphs.filter(graph => !val.includes(graph)))
    // }
    setMergedGraphs(val)
  }

  const onSubmit = (data) => {
    data._id = graph_det._id
    data.merged_graphs = mergedGraphs.map(graph => graph.value)
    data.colors = [data?.min_val_color, data?.mid_val_color, data?.max_val_color]
    data.unmerged_graphs = unmergedGraphs.filter(graph => !mergedGraphs.some(mgraph => mgraph.value === graph.value))
    data.unmerged_graphs = data.unmerged_graphs.map(graph=>graph.value)
    EditGraph(data).then((err) => {
      if (!err) {
        fetchGraphs()
        setCloseModal(false)
        window.location.reload()
      } else {
        alert(err)
      }
    })
  }

  return (
    <div className='modal' id='graph_setting'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <img onClick={() => { setCloseModal(false) }} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Select Sensor
          <select {...register('sensor_id')}>
            <option value={graph_det?.sensor_id?._id} defaultValue>{graph_det?.sensor_id?.name}</option>
            {sensorOptions?.map(sensor => <option value={sensor?._id}>{sensor?.name}</option>)}
          </select>
        </label>
        <label className='form-label' htmlFor="">
          Graph Name
          <input type="text"   {...register('name', { required: true })} />
        </label>
        {(graph_det.type === 'line' || graph_det.type === 'bar' || graph_det.type === 'gps') &&
          <label className='form-label' htmlFor="">
            Fetch Amount
            <input type="number" max={50} min={10}   {...register('fetch_data_period', { required: true })} />
          </label>
        }

        {(graph_det.type === 'line' || graph_det.type === 'bar') &&
          <>
            <label className='form-label' htmlFor="">
              Minimum Value
              <input type="text" {...register('min_threshold', { required: true })} />
            </label>
            {/* <label className='form-label' htmlFor="">
              Mid Value
              <input type="text" {...register('mid_threshold', { required: true })} />
            </label> */}
            <label className='form-label' htmlFor="">
              Maximum Value
              <input type="text" {...register('max_threshold', { required: true })} />
            </label>
            <div className='color-box'>
              <label className='form-label' htmlFor="">
                Min
                <input type="color" {...register('min_val_color', { required: true })} />
              </label>
              <label className='form-label' htmlFor="">
                Mid
                <input type="color" max={50} min={10}   {...register('mid_val_color', { required: true })} />
              </label>
              <label className='form-label' htmlFor="">
                Max
                <input type="color" max={50} min={10}   {...register('max_val_color', { required: true })} />
              </label>
            </div>
          </>
        }
        {graph_det.type === 'line' &&
          <label className='form-react-select' htmlFor="">
            Select graphs to merge
            {sensorOptions &&
              <Select
                // inputRef={ref}
                value={mergedGraphs}
                onChange={handleMergeGraphSelect}
                options={unmergedGraphs}
                isMulti
                styles={colourStyles}
              />
            }
            {/* {sensorOptions &&
              <Controller
                control={control}
                name="merged_graphs"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    inputRef={ref}
                    value={mergedGraphs}
                    onChange={(val, action) => onChange(() => { handleMergeGraphSelect(val, action) })}
                    options={unmergedGraphs}
                    isMulti
                    styles={colourStyles}
                  />
                )}
              />
            } */}
          </label>}
        <button className='form-but-1'>Save</button>
      </form>
    </div>
  )
}

export default GraphSettingForm