

// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';

// Chart.register(...registerables);

// function LineChart({ data, graph_det }) {
//   const [chartData, setChartData] = useState({});
//   const [minVal, setMinVal] = useState(Number.POSITIVE_INFINITY);
//   const [maxVal, setMaxVal] = useState(Number.NEGATIVE_INFINITY);
  
//   useEffect(() => {
//     if (data) {
//       let min = Number.POSITIVE_INFINITY;
//       let max = Number.NEGATIVE_INFINITY;
//       const labels = [];
//       const datasets = [];

//       data.forEach((dataset, i) => {
//         // Check if the dataset is in ascending order
//         const isAscending = dataset.every((d, idx) => {
//           if (idx === 0) return true;
//           return new Date(dataset[idx - 1].datetime) <= new Date(d.datetime);
//         });

//         if (!isAscending) {
//           dataset.reverse(); // Reverse only if it's not in ascending order
//         }

//         const dataPoints = dataset.map(d => {
//           const value = parseFloat(d.value);
//           min = Math.min(min, value); // Update min value
//           max = Math.max(max, value); // Update max value
//           const labelDate = d.datetime.split(' ')[0];
//           if (!labels.includes(labelDate)) {
//             labels.push(labelDate);
//           }
//           return { x: labelDate, y: value };
//         });

//         datasets.push({
//           label: graph_det.name,
//           data: dataPoints,
//           backgroundColor: 'rgba(255, 255, 255, 0.1)',
//           fill: true,
//           pointBackgroundColor: dataPoints.map(d => {
//             if (d.y <= graph_det.min_threshold) {
//               return graph_det.colors[0];
//             } else if (d.y <= graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             }
//             else if (d.y < graph_det.max_threshold && d.y > graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             } else if (d.y >= graph_det.max_threshold) {
//               return graph_det.colors[2];
//             }
            
//           }),
//           pointBorderColor: dataPoints.map(d => {
//             if (d.y <= graph_det.min_threshold) {
//               return graph_det.colors[0];
//             } else if (d.y <= graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             }
//             else if (d.y < graph_det.max_threshold && d.y > graph_det.mid_threshold) {
//               return graph_det.colors[1];
//             } else if (d.y >= graph_det.max_threshold) {
//               return graph_det.colors[2];
//             }
            
//           }),
//           pointRadius: 5, // Adjust point size if needed
//           segment: {
//             borderColor: ctx => {
//               const value = ctx.p1.parsed.y;
//               if (value <= graph_det.min_threshold) {
//                 return graph_det.colors[0];
//               } else if (value <= graph_det.mid_threshold) {
//                 return graph_det.colors[1];
//               }
//                 else if (value < graph_det.max_threshold && value> graph_det.mid_threshold) {
//                   return graph_det.colors[1];
                
//               } else if (value >= graph_det.max_threshold) {
//                 return graph_det.colors[2];
//               }
              
//             },
//           },
//         });
//       });

//       setChartData({
//         labels: [...new Set(labels)],
//         datasets,
//       });

//       setMinVal(min);
//       setMaxVal(max);
//     }
//   }, [data, graph_det]);

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       title: {
//         display: true,
//         text: 'Line Chart',
//       },
//     },
//     interaction: {
//       intersect: false,
//     },
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Date time',
//         },
//         ticks: {
//           autoSkip: true,
//           maxTicksLimit: 10,
//           maxRotation: 0,
//         },
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Value',
//         },
//         suggestedMin: minVal - 1,
//         suggestedMax: maxVal + 2 > minVal ? maxVal + 2 : minVal + 1,
//         ticks: {
//           stepSize: 1,
//           callback: function (value) {
//             return value.toFixed(0);
//           },
//         },
//       },
//     },
//     elements: {
//       line: {
//         tension: 0.4, // Smooth lines
//       },
//     },
//   };

//   return (
//     <div style={{ position: 'relative', height: '400px', width: '100%' }}>
//       {chartData.datasets && <Line data={chartData} options={options} />}
//     </div>
//   );
// }

// export default LineChart;



import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function LineChart({ data, graph_det }) {
  const [chartData, setChartData] = useState({});
  const [minVal, setMinVal] = useState(Number.POSITIVE_INFINITY);
  const [maxVal, setMaxVal] = useState(Number.NEGATIVE_INFINITY);
  

  
useEffect(() => {
  if (data) {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    const allLabels = new Set();
    const datasets = [];
    const isMergedGraph = graph_det.merged_graphs;
    const isGraphMerged = Array.isArray(isMergedGraph) && isMergedGraph.length > 0;

    console.log(isMergedGraph);
    const mergedGraphName = isGraphMerged ? graph_det.merged_graphs[0].name : null;
    const mergedGraphColor = isGraphMerged ? graph_det.merged_graphs[0].colors : null;
    
    



    data.forEach((dataset, i) => {
      // Ensure dataset is sorted by datetime
      const sortedData = [...dataset].sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

      const dataPoints = sortedData.map(d => {
        const value = parseFloat(d.value);
        min = Math.min(min, value); // Update min value
        max = Math.max(max, value); // Update max value

        const labelDate = d.datetime.split(' ')[0];
        allLabels.add(labelDate);

        return { x: labelDate, y: value };
      });
      const datasetLabel = i === 0 
      ? `${graph_det.name}` // First dataset gets the specific graph name
      : mergedGraphName;    // Second dataset gets the merged graph name
    
 

      datasets.push({
        label: datasetLabel,
        data: dataPoints,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        fill: true,
        pointBackgroundColor: dataPoints.map((point) => {
          const value = point.y || point; // Ensure correct value extraction for the point
          if (isMergedGraph && i === 1) {
            if (value <= graph_det.min_threshold) {
              return mergedGraphColor[0]; // First merged graph color for low values
            } else if (value >= graph_det.max_threshold) {
              return mergedGraphColor[2]; // Third merged graph color for high values
            }
            return mergedGraphColor[1]; // Default (middle) merged graph color
          }
          // Default logic for non-merged graphs
          if (value <= graph_det.min_threshold) {
            return graph_det.colors[0]; // First default color for low values
          } else if (value >= graph_det.max_threshold) {
            return graph_det.colors[2]; // Third default color for high values
          }
          return graph_det.colors[1]; // Default middle color
        }),
        pointBorderColor: dataPoints.map((d) => {
          const value = d.y || d; // Ensure correct value extraction for the point
          if (isMergedGraph && i === 1) {
            if (value <= graph_det.min_threshold) {
              return mergedGraphColor[0]; // First merged graph color for low values
            } else if (value >= graph_det.max_threshold) {
              return mergedGraphColor[2]; // Third merged graph color for high values
            }
            return mergedGraphColor[1]; // Default (middle) merged graph color
          }
          // Default logic for non-merged graphs
          if (value <= graph_det.min_threshold) {
            return graph_det.colors[0]; // First default color for low values
          } else if (value >= graph_det.max_threshold) {
            return graph_det.colors[2]; // Third default color for high values
          }
          return graph_det.colors[1]; // Default middle color
        }),
        
        pointRadius: 5,
        segment: {
          borderColor: ctx => {
            const value = ctx.p1.parsed.y;
        
            if (isMergedGraph && i === 1) {
              // Use merged graph colors
              if (value <= graph_det.merged_graphs[0].min_threshold) {
                return mergedGraphColor[0];
              } else if (value >= graph_det.merged_graphs[0].max_threshold) {
                return mergedGraphColor[2];
              }
              return mergedGraphColor[1];
            } else {
              // Use regular graph colors
              if (value <= graph_det.min_threshold) {
                return graph_det.colors[0];
              } else if (value >= graph_det.max_threshold) {
                return graph_det.colors[2];
              }
              return graph_det.colors[1];
            }
          },
        },
        
      });
    });

    // Convert Set to sorted Array for consistent labels
    const sortedLabels = Array.from(allLabels).sort();

    setChartData({
      labels: sortedLabels,
      datasets,
    });

    setMinVal(min);
    setMaxVal(max);
  }
}, [data, graph_det]);


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Line Chart',
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date time',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
        suggestedMin: minVal - 1,
        suggestedMax: maxVal + 2 > minVal ? maxVal + 2 : minVal + 1,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value.toFixed(0);
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smooth lines
      },
    },
  };

  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>
      {chartData.datasets && <Line data={chartData} options={options} />}
    </div>
  );
}

export default LineChart;
